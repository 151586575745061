// SplashScreen.scss
.splash-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #000;
    color: #fff;
    animation: fadeOut 1s ease-out 3.5s forwards;
  }
  
  .logo-container {
    animation: appearFromInfinite 1.5s ease-out, tornado 1.5s 1.5s ease-out forwards;
  }
  
  .splash-logo {
    width: 192px;
    height: 192px;
  }
  
  .splash-title {
    margin-top: 20px;
    font-size: 24px;
    text-align: center;
    opacity: 0;
    animation: fadeInOut 3s ease-in-out forwards;
  }
  
  @keyframes appearFromInfinite {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes tornado {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: rotate(360deg);
      opacity: 0;
    }
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  