.taki-container {
  font-family: Khula, Arial;
  background-position: center;
  background-size: cover;
  background-color: black;
  background-position: center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  font-family: 'Khula';
  font-style: normal;
}

.Khula{
  font-family: Khula, Arial;
}